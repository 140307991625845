@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
.LegendeGroupeCaseACocher {
  font-size: 1em;
  color: #223654;
  align-items: center;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: bold;
  margin-left: 5px;
}

.ChampsGroupeCaseACocher {
  display: block;
  border-width: 0px;
}

.description {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}
