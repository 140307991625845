@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
.App {
  min-height: 600px;
  max-width: 1440px;
  display: flex;
  justify-content: left;
  margin: auto;
  padding-top: 70px;
  width: 100%;
}

.App--pleine-largeur {
  min-height: 600px;
  display: flex;
  justify-content: left;
  margin: auto;
  padding-top: 70px;
}

.app-frame {
  min-width: 600px;
  margin: auto;
  padding: 30px 0 0 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, sans-serif;
  color: #223654;
  margin: 0;
  letter-spacing: 0;
}

h1::after {
  content: "";
  display: block;
  width: 50px;
  padding-top: 5px;
  border-bottom: 5px solid #ff8e7a;
}

h1 {
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
}

h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
}

h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
}

h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
}

h5 {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

p {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

strong {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}

.facteur-home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.notifications-conteneur {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .notifications-conteneur {
    bottom: 16px;
    right: 50%;
    transform: translate(50%, 0px);
  }
}

.lien-notification {
  margin-top: 25px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #095797;
  cursor: pointer;
  background-color: transparent;
  border: 0px;
}

.lien-notification + .lien-notification {
  margin-top: 25px;
}

ul {
  margin: 0;
}
li {
  line-height: 32px;
}
a {
  color: #095797;
}

.conteneur-liste {
  padding-right: 48px;
}

.liste-demo {
  list-style: none;
  padding-left: 0rem;
}

.ligne-haut {
  margin-left: 0.5rem !important;
  margin-top: 0.5rem !important;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

.ligne-bas {
  margin-left: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.ligne-message-attente {
  margin-bottom: 0.5rem !important;
}

.ligne-message-attente-bas {
  margin-top: 0.5rem !important;
}

.lienmodal {
  color: #095797;
}

.imgModal {
  margin: 2.75rem 0 0.5rem 0;
}

.infobulle_texte {
  display: flex;
  line-height: 40px;
}

.mc-contenu {
  margin-bottom: 48px;
}

.mc-contenu-surcharge {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 14px;
  color: #223654;
  display: flex;
  flex-direction: row;
  flex: auto;
  float: unset;
  line-height: 32px;
}

.mc-contenu-surcharge-no {
  font-weight: 400;
  font-size: 16px;
}

/* Style CSS pour le composant React Modal  */

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay--after-open {
  overflow: scroll;
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.mc-barre-horizontal {
  margin-top: 28px;
  width: 100%;
}

.mc-contenu-surcharge-no {
  font-weight: 400;

  font-size: 14px;
}

.pwd-strength-meter {
  width: 100%;
  padding-left: 150px;
}

.pwd-strength-meter-short {
  max-width: 160px;
  width: 100%;
}

.pwd-strength-meter-medium {
  max-width: 400px;
  width: 100%;
}

.pwd-strength-meter-long {
  max-width: 800px;
  width: 100%;
}

.pwd-strength-meter-xlong {
  max-width: 1200px;
  width: 100%;
}

.entete {
  width: 100%;
  height: 1200px;
}

.piedEntete {
  width: 100%;
  margin-top: 1200px;
}

.containerEntete {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
