.tuile p {
  font-size: 14px;
  color: #223654;
  margin: 0;
  font-weight: 400;
  line-height: 20px;
}
.tuile-header h2 {
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}
.tuile {
  box-sizing: border-box;
  padding: 32px 24px 24px;
  width: 250px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid #c5cad2;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  cursor: pointer;
  margin: 0;
  height: fit-content;
  border-radius: 4px;
}
.tuile-header {
  display: flex;
  align-items: center;
  gap: 8px;
}
