.logo {
  margin-left: 5px;
}

.conteneur {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  color: #223654;
}

.lien {
  color: #223654 !important;
}
