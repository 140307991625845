@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.dialogueModal p {
  margin: 0;
}

.dialogueModalAlert p {
  margin-top: 0;
  margin-bottom: 44px;
}

.dialogueModal,
.dialogueModalAlert {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate(-50%, -0%);
  border-radius: 0;
  background: #fff;
  outline: none;
  padding: 32px;
  max-width: 600px;
  width: 55%;
  min-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: #223654;
}

.dialogueModal h2,
.dialogueModalAlert h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.75rem;
}

.dialogueModalAlert {
  overflow: hidden;
}

.recouvert {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 54, 84, 0.5);
}

.enTeteDialogue {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}

.ligne_sous_titre {
  border-bottom: 4px solid #ff8e7a;
  width: 50px;
  padding-top: 4px;
}

.imgAlerte {
  display: inline-block;
}

.imgFermeture {
  position: absolute;
  display: inline-block;
  margin-left: 50px;
  cursor: pointer;
  right: 16px;
  top: 16px;
  border: none;
  background: none;
}

.contenuDialogueModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
}

/* Style CSS pour le composant React Modal  */

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay--after-open {
  overflow: scroll;
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modalLien {
  color: #095797;
}

.modal-bouton {
  display: flex;
  justify-content: flex-end;
}

.modal-lien {
  border: none !important;
  background: none !important;
  color: #095797;
  font-size: 16px;
  padding: 12px;
  text-decoration: underline;
  display: flex;
}
.modal-lien:hover {
  cursor: pointer;
}

/** Version cellulaire */
@media only screen and (max-width: 768px) {
  .enTeteDialogue {
    margin-bottom: 24px;
  }

  .imgFermeture {
    margin-left: 16px;
  }

  .contenuDialogueModal {
    margin-bottom: 19px;
  }

  .modal-bouton {
    flex-direction: column-reverse;
  }

  .modal-bouton .btn + .btn {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
