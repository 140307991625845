.facteur-conteneur {
  border: 2px solid #c5cad2;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  min-width: 464px;
  min-height: 120px;
}

.facteur-texte-conteneur {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 32px;
  width: 100%;
  flex: 1;
}

.facteur-texte-courant {
  font-size: 16px;
  color: #223654;
  line-height: 24px;
}

.facteur-code {
  font-family: Roboto, sans-serif;
  font-size: 48px;
  color: #223654;
  font-weight: bold;
  margin: auto;
}

.facteur-contenu {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}
.wrap-email {
  max-width: 621px;
  word-break: break-all;
}
.facteur-sm {
  max-width: 277px;
}

.facteur-md,
.facteur-lg,
.facteur-xlg {
  max-width: 856px;
}

.facteur-md {
  min-width: 527px;
}
.facteur-lg {
  min-width: 599px;
}
.facteur-xlg {
  min-width: 609px;
}

.facteur-email {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  font-family: Roboto, sans-serif;
  color: #223654;
}

@media only screen and (max-width: 768px) {
  .facteur-conteneur {
    min-width: unset;
  }
  .facteur-texte-conteneur {
    padding: 14px;
  }

  .facteur-md,
  .facteur-lg,
  .facteur-xlg {
    min-height: 78px;
  }

  .facteur-sm {
    width: fit-content;
    min-height: 78px;
  }

  .facteur-texte-courant {
    font-size: 14px;
  }

  .facteur-email {
    font-size: 19px;
    line-height: 24px;
  }
  .facteur-code {
    font-size: 30px;
  }
}
