.conteneur-aide {
  height: 40px;
}

.info-lien {
  border: none !important;
  background: none !important;
  padding: 12px;
  display: flex;
  cursor: pointer;
}

.inside_tooltip {
  line-height: 24px;
  font-family: Open Sans;
  font-size: 14px;
  max-height: 118px;
  max-width: 320px;
  min-width: calc(216px - 44px);
  margin: 28px 8px 16px 0;
  padding: 0 16px;
  overflow-y: auto;
  scrollbar-color: #095797 #fff;
  scrollbar-width: thin;
  white-space: pre-line;
}

.inside_tooltip::-webkit-scrollbar {
  width: 8px;
  height: 40px;
  border-radius: 10px;
  background-color: #fff;
}

.inside_tooltip::-webkit-scrollbar-track {
  border-radius: 10px;
}

.inside_tooltip::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #095797;
}

.imgFermetureTT {
  position: absolute;
  right: 0;
  margin: 8px 8px 0 0;
  cursor: pointer;
  border: none;
  padding: unset;
  background: none;
}

.popup_desktop {
  max-height: 158px;
  max-width: 318px;
  min-width: 214px;
  border: 1px solid #c4c8ce;
  line-height: 22px;
  background: #fff;
  -webkit-box-shadow: 1.6px 1.6px 7px #c4c8ce;
  box-shadow: 1.6px 1.6px 7px #c4c8ce;
}

.infobulle_texte {
  display: flex;
  line-height: unset;
}

.popup:before {
  position: absolute;
  content: "";
  width: 11.43px;
  height: 11.43px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
}

.bottom:before {
  top: -4.91px;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-box-shadow: -1px -1px 0 0 #c4c8ce;
  box-shadow: -1px -1px 0 0 #c4c8ce;
}

.bottom.center:before {
  margin-left: -4.91px;
}

.bottom.left:before {
  left: 16px;
}

.bottom.right:before {
  right: 16px;
  left: auto;
}

.top:before {
  top: auto;
  right: auto;
  bottom: -4.91px;
  left: 50%;
  -webkit-box-shadow: 1px 1px 0 0 #c4c8ce;
  box-shadow: 1px 1px 0 0 #c4c8ce;
}

.top.center:before {
  left: 50%;
}

.top.left:before {
  left: 16px;
}

.top.right:before {
  right: 16px;
  left: auto;
}

.left.center:before {
  top: 50%;
  bottom: auto;
  margin-top: -4.91px;
  right: -4.91px;
  left: auto;
  -webkit-box-shadow: 1px -1px 0 0 #c4c8ce;
  box-shadow: 1px -1px 0 0 #c4c8ce;
}
.right.center:before {
  top: 50%;
  bottom: auto;
  margin-top: -4.91px;
  left: -4.91px;
  right: auto;
  -webkit-box-shadow: -1px 1px 0 0 #c4c8ce;
  box-shadow: -1px 1px 0 0 #c4c8ce;
}

@media only screen and (max-width: 768px) {
  .popup {
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }

  .popup_mobile {
    top: inherit;
    z-index: 300;
    min-width: 100%;
    width: 100vw;
    overflow-y: unset;
    max-height: 33vh;
    background: #fff;
    padding: 16px 0;
    border: unset;
  }

  .inside_tooltip_mobile {
    font-family: Open Sans;
    font-size: 14px;
    margin-right: 40px;
    margin-left: 16px;
    margin-bottom: 16px;
    white-space: pre-line;
  }

  .inside_tooltip_mobile_conteneur {
    overflow-y: auto;
    height: 100%;
  }

  .inside_tooltip_mobile_conteneur::-webkit-scrollbar {
    width: 8px;
    height: 24px;
    border-radius: 10px;
    background-color: #fff;
  }

  .inside_tooltip_mobile_conteneur::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .inside_tooltip_mobile_conteneur::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #095797;
  }

  .imgFermetureTT {
    margin: 0 16px 0 0;
  }

  .popup:before {
    display: none;
  }
  .infobulle_mobile {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
