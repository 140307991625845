@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, sans-serif;
  color: #223654;
  margin: 0;
  letter-spacing: 0;
}

h1::after {
  content: "";
  display: block;
  width: 50px;
  padding-top: 5px;
  border-bottom: 5px solid #ff8e7a;
}

h1 {
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
}

h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
}

h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
}

h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
}

h5 {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
p {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}
strong {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}
ul {
  margin: 0;
}
a {
  color: #095797;
}

.texte_titre {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}
