@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.titreBarreProgression {
  position: relative;
  margin-top: -36px;
  margin-right: -68px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

#zoneTitre {
  text-decoration: none;
}

.zone-click {
  height: 80px;
  text-decoration: none;
}

.BarreConteneur,
#BarreConteneur {
  display: flex;
  justify-content: center;
}

.ligne {
  width: 146px;
  position: relative;
  border-radius: 2px;
  height: 4px;
  text-decoration: none;
  margin-top: 24px;
  border-top-width: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}

.EnCours {
  color: #095797;
  background-color: #095797;
}

.Succes {
  color: #223654;
  background-color: #223654;
}

.Neant {
  color: #6a778a;
  background-color: #c5cad2;
}

.zone-click:hover .Neant,
.zone-click:focus .Neant,
.zone-click:hover .Succes,
.zone-click:focus .Succes {
  color: #095797;
}

.zone-click:hover .puce.Neant,
.zone-click:focus .puce.Neant {
  color: #095797;
  background-color: #dae6f0;
  box-shadow: 0px 0px 0px 1px #095797;
}

.zone-click:hover .texteEtape.Neant,
.zone-click:focus .texteEtape.Neant,
.zone-click:hover .texteEtape.Succes,
.zone-click:focus .texteEtape.Succes {
  color: #095797;
  text-decoration: underline;
}

.zone-click:hover .puce.Succes,
.zone-click:focus .puce.Succes {
  color: #095797;
  background-color: #095797;
}

.puce {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: -13px;
  left: calc(44% - 5px);
}

.puce.Neant {
  background-color: white;
  box-shadow: inset 0px 0px 0px 1px #c5cad2;
}

.puce.EnCours {
  background-color: #095797;
  color: white;
}

.puce.Succes {
  background-color: #223654;
}

.textePuce {
  text-align: center;
  vertical-align: middle;
  position: relative;
  top: 2%;
  right: 1%;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 3px;
}

.texteEtape {
  font-size: 14px;
  position: relative;
  text-align: center;
  top: 24px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  height: fit-content;
  background-color: white;
}

.texteEtape.EnCours,
.texteEtape.Succes {
  color: #223654;
  font-weight: bold;
}

.texteEtape.Neant {
  color: #6a778a;
}

.imgV {
  position: relative;
  margin-top: 2px;
}

.titreBarre {
  font-family: "Open sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.zone-click + .zone-click {
  margin-left: 8px;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile {
    display: initial;
  }

  .BarreConteneur .chevron {
    position: relative;
    height: min-content;
    width: 16px;
    color: #095797;
    float: right;
  }

  .BarreConteneur .chevron img {
    width: 16px;
  }

  .processus {
    text-decoration: none;
    color: #223654;
    padding: 8px 16px 6px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    font-family: "Open Sans", serif;
  }

  .processus p {
    margin: 0px;
    font-size: 14px;
    line-height: 19px;
  }

  .BarreConteneur {
    flex-direction: column;
    border: 1px solid #6a778a;
    width: calc(100% - 32px);
    height: fit-content;
    min-width: 300px;
  }

  .BarreConteneur:hover {
    color: #095797;
  }

  #BarreConteneur {
    flex-direction: column;
    height: fit-content;
  }

  #BarreConteneur.active {
    display: none;
  }

  #zoneTitre {
    padding: 17px 16px 17px 0;
    background-color: #dae6f0;
    display: flex;
  }

  #zoneTitre.active {
    display: none;
  }

  .titreBarreProgression {
    display: flex;
    margin-top: unset;
    margin-right: unset;
    margin-left: 56px;
    width: 100%;
  }

  .titreBarre {
    font-family: "open sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .puce {
    width: 24px;
    height: 24px;
    top: 7px;
    left: -12px;
  }

  .textePuce {
    top: -4%;
    font-size: 0.875rem;
  }

  .texteEtape {
    top: 10px;
    text-align: left;
  }

  .imgV {
    width: 10.56px;
    height: 13.5px;
  }

  .ligne {
    height: 38px;
    width: 0px;
    border: 1px solid;
    margin: 0 26px 0 28px;
  }

  #debut {
    height: 30px;
    margin-left: 28px;
    margin-top: 8px;
  }

  #debut .puce {
    top: -2px;
  }

  .zone-click {
    height: 40px;
    display: flex;
  }

  .zone-click + .zone-click {
    margin-left: 0px;
    margin-top: 8px;
  }
}
