.conteneurPrincipal {
  width: 280px;
  align-content: flex-start;
}

.titreDesktop {
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 49px;
  text-align: left;
  box-sizing: border-box;
  padding: 13px 17px;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #095797;
}

.conteneurEnfant {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 49px;
  border-top: 1px solid rgba(197, 202, 210, 0.7);
  text-align: left;
  box-sizing: border-box;
  padding: 13px 17px;
}

.conteneurEnfant.actif,
.conteneurEnfant:hover {
  background-color: #dae6f0;
}

.texteBouton {
  text-decoration: none;
  display: flex;
}

.texteBouton[aria-disabled="true"] {
  pointer-events: none;
}

.texteBouton:last-child .conteneurEnfant {
  border-bottom: 1px solid rgba(197, 202, 210, 0.7);
}

.texte-icone {
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #095797;
}

.texte-icone.actif {
  font-weight: 700;
}

.mobileOnly {
  display: none;
}

@media only screen and (max-width: 990px) {
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: initial;
  }

  #myLinks {
    display: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-style: normal;
    color: #095797;
  }

  #myLinks.active {
    display: block;
  }

  i {
    color: #095797;
  }

  .conteneurPrincipal {
    width: 100%;
    height: fit-content;
    min-width: 248px;
  }

  .conteneurPrincipal a.icon {
    background: #dae6f0;
    color: #095797;
    text-decoration: none;
    display: block;
    font-weight: bold;
    padding: 12px 22px;
    min-height: 49px;
    box-sizing: border-box;
  }

  .conteneurPrincipal a.icon i {
    float: right;
    margin-left: 22px;
    font-size: 19px;
  }

  .conteneurPrincipal #myLinks {
    display: none;
  }

  .conteneurPrincipal #myLinks.active {
    display: block;
  }

  .conteneurEnfant {
    background: #dae6f0;
    border: none;
    padding: 12px 22px 12px 42px;
  }

  .conteneurPrincipal a.icon {
    background: #dae6f0;
    display: block;
    font-weight: 500;
    padding: 14px 22px;
  }

  .conteneurPrincipal .chevron {
    float: right;
  }

  .conteneurPrincipal .chevron img {
    width: 19px;
  }

  .conteneurPrincipal a:hover {
    background-color: #dae6f0;
    color: #095797;
    font-weight: 500;
  }

  .texteBouton:last-child .conteneurEnfant {
    border: none;
  }

  .actif {
    background-color: #dae6f0;
    color: #095797;
    font-weight: 500;
  }
}

@media only screen and (max-width: 550px) {
  .conteneurPrincipal {
    margin-top: 24px;
  }
}
