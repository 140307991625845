@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.ms-progress-wrapper_A043BXZ .ms-progress-container_XH4B78M {
  padding: unset;
  margin: unset;
}


.mc-wrapProgressBox {
  margin: 0 auto;
  position: relative;
}

.mc-progressbox {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
}

.mc-progressbox-logo {
  width: 80px;
  height: 80px;
  text-align: center;
  padding-top: 15px;
  z-index: 1;
  background: transparent;
}

.mc-imgFleurDeLys {
  height: 50px;
}




