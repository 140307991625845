@import url("https://fonts.googleapis.com/css2?family=Roboto");

html {
  scroll-behavior: smooth;
}

.en-tete-de-page {
  background-color: #095797;
  width: 100%;
  min-height: 72px;
  height: fit-content;
  display: flex;
}
.en-tete-de-page-partie-deux {
  background-color: #dae6f0;
  width: 100%;
  min-height: 70px;
  height: fit-content;
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.en-tete-de-page-partie-deux div {
  width: 100%;
  max-width: 1152px;
  margin: 0 auto;
}
.en-tete-de-page a {
  color: #ffffff;
  text-decoration: none;
}

.en-tete-de-page a:hover {
  text-decoration: underline;
}

.en-tete-de-page .conteneur-menu {
  margin: 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1152px;
}

.en-tete-de-page .conteneur-logo {
  display: flex;
  align-items: center;
}

.en-tete-de-page .conteneur-nom-service {
  margin-left: 75px;
  margin-right: 1%;
  order: 2;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

.en-tete-de-page .conteneur-lien a {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
}

.en-tete-de-page .conteneur-loupe-liens {
  order: 3;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  height: max-content;
  align-items: center;
  margin-left: auto;
}

.en-tete-de-page .conteneur-loupe img {
  height: 25px;
}

.en-tete-de-page .conteneur-lien {
  margin: 5px;
  margin-left: 2.22%;
  margin-right: 0px;
  display: flex;
  flex-flow: column wrap;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}

.en-tete-de-page .conteneur-langue {
  margin: 2px;
  display: flex;
}

.en-tete-de-page .conteneur-nous-joindre {
  margin: 2px;
  white-space: nowrap;
  display: flex;
}

@media only screen and (max-width: 1300px) {
  .en-tete-de-page .conteneur-menu {
    max-width: 100%;
    margin: 0 72px;
  }
}

@media only screen and (max-width: 850px) {
  .en-tete-de-page .conteneur-menu {
    flex-wrap: wrap;
    margin: 16px;
  }

  .en-tete-de-page .conteneur-logo svg {
    width: 202px;
    height: 63px;
    flex-shrink: 0;
    padding: 0 16px 8px 0;
  }

  .en-tete-de-page .conteneur-loupe-liens {
    order: 2;
    flex-shrink: 0;
  }

  .en-tete-de-page .conteneur-lien {
    order: 2;
    flex-shrink: 0;
  }

  .en-tete-de-page .conteneur-loupe img {
    width: 24px;
  }

  .en-tete-de-page .conteneur-nom-service {
    order: 3;
    flex-shrink: 0;
    margin-top: 4px;
    flex-basis: 100%;
    font-weight: 400;
    margin-left: 0;
  }
}
