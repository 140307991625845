.card-conteneur {
  background-color: #ffffff;
  padding: 72px;
  margin: 72px 72px 120px;
  display: -webkit-inline-box;
  max-width: 1440px;
}

@media only screen and (max-width: 990px) {
  .card-conteneur {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

@media only screen and (max-width: 550px) {
  .card-conteneur {
    padding: 16px;
  }
}
