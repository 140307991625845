.editor-container {
  width: 850px;
  max-width: auto;
}

.editor-container .jodit-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.editor-container .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
}

.button-container .btn {
  padding: 6px 22px;
}
