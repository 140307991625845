.sidebar {
  position: fixed;
  top: 0;
  left: -12.5rem;
  width: 12.5rem;
  height: 100%;
  background-color: hsl(207, 89%, 31%);
  transition: left 300ms ease-out;
  overflow-x: hidden;
  font-family: "Open sans", Arial, sans-serif;
  z-index: 1;
}

.sidebar.active {
  left: 0;
}

.sidebar ul {
  margin: 0;
  padding: 0;
}

.sidebar li {
  list-style: none;
}

.sidebar li a {
  font-size: 14px;
  text-decoration: none;
  padding: 6px;
  display: block;
  color: #fff !important;
}

.hamburger {
  border: none;
  outline: 0;
  width: 30px;
  position: absolute;
  left: 0;
  transition: left 300ms ease-out;
  background-color: #095797;
}
.hamburger.active {
  left: 12.5rem;
}
.hamburger:after,
.hamburger:before,
.hamburger div {
  background-color: #fff;
  height: 2px;
  margin: 5px 0;
  border-radius: 3px;
  content: "";
  display: block;
  transition: all 300ms ease-in-out;
}

.sidebar.active .hamburger:before {
  transform: translateY(7px) rotate(135deg);
}

.sidebar.active .hamburger::after {
  transform: translateY(-7px) rotate(-135deg);
}

.sidebar.active .hamburger div {
  transform: scale(0);
}
