.gauche {
  margin-right: 8px;
  color: #095797;
}
.link {
  text-decoration: none;
}
.paragraphLink {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
