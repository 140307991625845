.textarea-container {
  border: 1px solid #d0d0d0;
  padding: 0 5px 5px 5px;
  background-color: #fff;
  margin: 1.6% 0;
}

.textarea {
  -moz-appearance: none;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  background-color: #fff;
  width: 650px;
  height: 200px;
}

.counter {
  bottom: 0;
  right: 0;
  font-style: italic;
  font-size: 14px;
  color: #a8a5a5;
  text-align: right;
}

.textarea.petit-area {
  height: 80px;
}
