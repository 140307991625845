.conteneur {
  text-align: start;
  margin-top: 24px;
}

.divtextbox {
  float: left;
}

.titre {
  text-align: left;
}
