.notifications-conteneur {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: fit-content;
}

.fading {
  animation: fading 0.5s;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 100;
  }
}

.fadeOut {
  animation: fadeOut 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  0% {
    opacity: 100;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.image-bouton {
  position: absolute;
  top: 18px;
  right: 16px;
  cursor: pointer;
  border: 0px;
  padding: 0px;
}

.conteneur-notification {
  max-width: 281px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
}

.notification-contextuelle {
  position: relative;
  max-width: 344px;
  width: 100%;
  min-width: 344px;
  border-radius: 0px;
  box-shadow: 0 3px 6px 0 rgba(34, 54, 84, 0.16);
}

.notification-contextuelle-typo {
  position: relative;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #ffffff;
  margin: 16px 0px;
  line-height: 24px;
}

.notification-contextuelle + .notification-contextuelle {
  margin-top: 16px;
}

.positif {
  background: #4f813d;
}

.neutre {
  background: #095797;
}

.negatif {
  background: #cb381f;
}

@media only screen and (max-width: 768px) {
  .notification-contextuelle {
    max-width: 343px;
    min-width: 100%;
  }

  .notification-contextuelle-typo {
    margin-right: 16px;
  }
}
