.barreBleu,
.barreJaune {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0;
  width: 100%;
  min-width: 323px;
  margin: 0 0 auto;
  box-sizing: border-box;
}

.barreBleu {
  display: flex;
  background: #223654;
}

.barreJaune {
  background: #f8e69a;
}

.barreBleu .conteneurBarre,
.barreJaune .conteneurBarre {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1152px;
  margin: 0 auto;
}

@media only screen and (max-width: 1300px) {
  .barreBleu .conteneurBarre,
  .barreJaune .conteneurBarre {
    margin: 0 72px;
  }
}

@media only screen and (max-width: 850px) {
  .barreBleu .conteneurBarre,
  .barreJaune .conteneurBarre {
    margin: 0 16px;
  }
}

.en-tete-de-page-jaune {
  background-color: #f8e69a;
  width: 100%;
  height: fit-content;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.en-tete-de-page-jaune div {
  width: 100%;
  max-width: 1152px;
  margin: 0 auto;
}

.pictogramme {
  width: 24px;
  height: 24px;
  margin: 24px 8px -6px 8px;
}
