.conteneur_principal {
  width: 100%;
  display: flex;
}

.conteneur_titre {
  text-align: left;
  display: flex;
}

.texte-titre {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  color: #223654;
  margin: 0 0 20px 0;
}

fieldset {
  border: 0;
  display: block;
  margin: 0;
  padding: 0;
}

.conteneur_dialogue {
  display: flex;
  color: #223654;
  max-width: 768px;
}

input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #808a9d;
  box-sizing: border-box;
  margin-right: 8px;
  border-radius: 12px;
  background-clip: content-box;
  padding: 2px;
  display: flex;
  cursor: pointer;
}

input[type="radio"]:focus {
  border: 2px solid #5b6476;
  outline: 2px solid rgba(20, 121, 204, 0.5);
  outline-offset: 0px;
}

input[type="radio"]:checked {
  background-color: #1479cc;
  border: 1px solid #808a9d;
}

input[type="radio"]:disabled {
  cursor: default;
  border-color: #bfc4ce;
}

.libelleRadio[aria-disabled="true"] {
  color: #bfc4ce;
  cursor: default;
}

.libelleRadio {
  color: #223654;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
}
.libelleRadio.important {
  color: #b86404;
  background-image: url(../../icones/important.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 20px;
  padding-left: 25px;
  font-weight: bold;
}
.libelleRadio.critique {
  color: #cf0909;
  background-image: url(../../icones/exclamation.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 20px;
  padding-left: 25px;
  font-weight: bold;
}

.conteneur-champTexte {
  width: 100%;
}

.conteneur_champs {
  margin-top: -20px;
  margin-bottom: 20px;
}

.conteneur_champs .texte-conteneur {
  margin-bottom: 20px;
}

.texte_precision {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  display: block;
  color: #223654;
  margin-bottom: 4px;
}

.conteneur_aide {
  display: inline-flex;
  position: relative;
  bottom: 10px;
  height: 40px;
}
