@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.conteneurConditionUtilisation .conteneurCaseACocher,
.conteneurConditionUtilisationErreur .conteneurCaseACocher {
  height: 37.5px;
  width: 32px;
}

.conteneurCaseACocher {
  display: flex;
}

.libelle {
  font-family: "Open Sans", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  cursor: pointer;
  color: #223654;
  padding-left: 8px;
  position: relative;
}
.conteneurCaseACocherInfobulle {
  display: flex;
}

/* Désigne le crochet du checkbox */
.conteneurCaseACocher label::after {
  content: "";
  position: absolute;
  left: -11px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
}

.conteneurCaseACocher input[type="checkbox"]:checked + label::after,
.conteneurCaseACocher input[type="checkbox"]:enabled + label:active::after,
.conteneurCaseACocher input[type="checkbox"]:enabled:active + label::after {
  opacity: 1;
}

.libelle * {
  margin: unset;
}

.conteneurCaseACocher input[type="checkbox"] {
  cursor: pointer;
  border: 1px solid #808a9d;
  height: 15px;
  min-width: 15px;
  margin: 6px 0 0 0;
  background-color: #fff;
  appearance: none;
}

.conteneurCaseACocher input[type="checkbox"]:checked {
  background-color: #1479cc;
  border: 1px solid #808a9d;
}

.conteneurCaseACocher input[type="checkbox"]:disabled,
.conteneurCaseACocher input[type="checkbox"]:checked:disabled {
  border: 1px solid #bfc4ce;
  color: #bfc4ce;
  cursor: default;
}

.conteneurCaseACocher input[type="checkbox"]:disabled + label {
  color: #223654;
  opacity: 0.5;
  cursor: default;
}

.conteneurCaseACocher input[type="checkbox"]:enabled:active,
.conteneurCaseACocher input[type="checkbox"]:checked:focus {
  background-color: #1479cc;
  border: 1px solid #808a9d;
  box-shadow: 0px 0px 0px 2px #89bce5;
}

.conteneurCaseACocher input[type="checkbox"]:focus {
  border: 1px solid #5b6476;
}

.conteneurCaseACocher input[type="checkbox"]:focus-visible {
  outline: none;
  border: 1px solid #5b6476 !important;
  box-shadow: 0px 0px 0px 2px #89bce5;
}

.description {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.zoneClick {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  left: -38px;
  top: -2px;
}

.conteneurDiv {
  display: flex;
  max-width: 857px;
  width: 100%;
  padding: 11px 11px 11px 0;
  position: relative;
  box-sizing: border-box;
}

.conteneurDiv.caseACocherErreur {
  border: 2px solid #cb381f;
  padding: 11px;
}

.conditionUtilisation {
  padding: 17px 22px !important;
  background: rgba(218, 230, 240, 0.4);
}

.messageErreur {
  position: relative;
  display: block;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #cb381f;
}

.conteneurConditionUtilisation + #alerte {
  visibility: hidden;
}

.conteneurConditionUtilisationErreur + #alerte {
  opacity: 1;
}
