.content + .content,
.content + .content *,
.content .list .content {
  margin-top: 16px !important;
}

.content {
  margin-left: 8px;
  line-height: 24px;
}

.list {
  padding-left: 8px;
}

.content::marker {
  color: #223654 !important;
  font-size: 14px;
}

.list.disc {
  list-style-type: disc;
}

.list.circle {
  list-style-type: circle;
}

.list.decimal {
  list-style-type: decimal;
}

.list-container {
  padding-left: 16px;
}

.default {
  color: #223654 !important;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.content * {
  text-align: left;
}

.content a {
  color: #095797 !important;
}
