.bar-container {
  max-width: 528px;
  width: 100%;
}

.bars {
  display: flex;
  justify-content: center;
}

.bar {
  width: 25%;
  height: 4px;
  border-radius: 2.5px;
  background-color: rgba(197, 202, 210, 0.4);
  margin: 8px 0;
}

.bar + .bar {
  margin-left: 6px;
}

.label {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.word {
  display: flex;
  justify-content: flex-end;
}

.weak {
  background-color: #cb381f !important;
}

.word-weak {
  color: #cb381f;
}

.medium {
  background-color: #cd7105 !important;
}

.word-medium {
  color: #cd7105;
}

.strong {
  background-color: #4f813d !important;
}

.word-strong {
  color: #4f813d;
}

.word-invalid {
  color: #6a778a;
}

@media screen and (max-width: 960px) {
  .bar + .bar {
    margin-left: 1%;
  }
}

@media only screen and (max-width: 768px) {
  .bar + .bar {
    margin-left: 4.3px;
  }
  .bar-container {
    max-width: 343px;
    width: 100%;
  }
}
