.conteneur-principale {
  background-color: #f0f0f0;
  height: 100%;
  width: 100%;
}

.menu-vertical {
  padding-top: 150px;
}

.conteneurPrincipal {
  margin-right: 80px;
}

.contenu-form {
  display: table-cell;
}

h1::after {
  content: "";
  display: block;
  width: 50px;
  padding-top: 5px;
  border-bottom: 5px solid #ff8e7a;
}

.page-titre {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: #223654;
  margin-top: 48px;
  position: absolute;
}

@media only screen and (max-width: 990px) {
  .conteneurPrincipal {
    margin-right: 0;
  }

  .conteneur-principale {
    width: fit-content;
  }
}
