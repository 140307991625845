.min-width {
  min-width: 400px;
}

.react-select__control,
.react-select__menu {
  border-radius: 0 !important;
  border: 1px solid #909aa9 !important;
}
.react-select__control {
  display: flex;
  background-color: #fff;
  max-width: 528px;
}
.react-select__control:active,
.react-select__control:focus,
.react-select__control--is-focused,
.react-select__control--menu-is-open {
  border-radius: 0 !important;
  outline: 2px solid #4a98d9 !important;
  border: 1px solid #5b6476 !important;
}
.react-select__indicator-separator {
  background-color: transparent !important;
}
div.react-select__menu {
  margin-top: -1px;
  border-left: 1px solid #909aa9 !important;
  border-right: 1px solid #909aa9 !important;
  border-bottom: 1px solid #909aa9 !important;
  border-top: #fff !important;
  box-shadow: none;
  z-index: 1000;
  max-width: 528px;
  background-color: #fff;
  margin-top: -3px;
}
.react-select--is-disabled .react-select__indicators,
.react-select--is-disabled .react-select__value-container {
  background-color: #e9ecef;
  opacity: 1;
}
.react-select__placeholder {
  color: #6b778a !important;
}
.react-select__single-value--is-disabled {
  color: #495057 !important;
}

/*** Scrollbar ***/

div.react-dropdown-tree-select ::-webkit-scrollbar,
div.react-select__menu ::-webkit-scrollbar,
.rdl-control-container ::-webkit-scrollbar {
  width: 8px;
}

div.react-dropdown-tree-select ::-webkit-scrollbar-track,
div.react-select__menu ::-webkit-scrollbar-track,
.rdl-control-container ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
  border-radius: 0px;
}

div.react-dropdown-tree-select ::-webkit-scrollbar-thumb,
div.react-select__menu ::-webkit-scrollbar-thumb,
.rdl-control-container ::-webkit-scrollbar-thumb {
  background: #095797;
  border-radius: 0px;
}

div.react-dropdown-tree-select ::-webkit-scrollbar-thumb:hover,
div.react-select__menu ::-webkit-scrollbar-thumb:hover,
.rdl-control-container ::-webkit-scrollbar-thumb:hover {
  background: #095797;
}

/*** LISTES DÉROULANTES INVALID ***/

.was-validated.react-select__container:invalid,
.react-select__container.is-invalid > div.react-select__control {
  border: 3px solid #cb381f !important;
  background-image: none;
}
.is-invalid > div > div > .react-select__indicators {
  color: #cb381f !important;
}

.erreur-message {
  color: #cb381f;
  font-weight: 600;
  margin-top: 8px;
}
