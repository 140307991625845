.main-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.icon-box {
  display: flex;
  border: 1px solid #c5cad2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  height: 80px;
  border-radius: 2px;
  margin: 10px;
  padding: 5px;
}

.noire {
  background-color: #000000;
  color: #ffffff;
}

.bleue {
  background-color: #095797;
  color: #ffffff;
}

.blanc {
  background-color: white;
  color: #000000;
}
