.header {
  display: flex;
  padding: 10px;
  color: #223654;
  background-color: #f5f5f5;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.header:hover {
  background-color: #f5f5f5;
}

.inside {
  border: 1px solid #c5cad2;
  border-top: none;
  padding: 0 10px;
}

.inside-content {
  padding: 20px 5px;
}

path {
  fill: #223654;
}

.label-masque {
  margin: 0 8px;
}

.header-right {
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.titre {
  font-weight: 600;
}
