.bloc-commentaire {
  max-width: 75%;
  min-width: 500px;
  border: 1px solid #d0d0d0;
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 1.6% 0;
}

.commentaire {
  max-width: 100%;
  min-width: 100%;
  font-size: 15px;
}
